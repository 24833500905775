.virtual-keyboard {
  position: fixed;
  width: 100%;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 6px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, .8);

  &-row {
    display: flex;
    gap: 4px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &-key {
    color: #000;
    border-radius: 3px;
    max-width: 40px;
    flex: 2;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e8efec;
    text-align: center;
    outline: none;
    border: 0;
  }

  &-key-green {
    background: var(--main-green-color-light);
    color: #fff;
  }

  &-key-green:disabled {
    background: #7a9086;
  }

  &-key-big {
    max-width: 68px;
    flex: 3;
  }

  &-key-half {
    display: inline-block;
    flex: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .virtual-keyboard {
    display: none;
  }
}
