.game-end-modal {
  position: fixed;
  top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.game-end-content {
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  padding: 24px;
  margin: 0 24px;
  max-width: 420px;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--scrollbar-border);
}
