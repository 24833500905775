.admin-container {
  width: 100%;
  height: 100%;

  button {
    margin-bottom: 20px;
    width: 120px;
    height: 40px;
    border: 2px solid var(--main-green-color-light);
    color: var(--main-green-color-light);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    font-size: 16px;
  }

  button:hover {
    background-color: var(--main-green-color-light);
    cursor: pointer;
    color: white;
  }

  select {
    outline: none;
    width: 243px;
    height: 40px;
    border: 2px solid var(--main-green-color-light);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    color: var(--main-green-color-light);
  }

  select:hover {
    background-color: var(--main-green-color-light);
    color: white;
  }

  select:focus {
    border: 2px solid var(--main-green-color-light);
  }

  &-wrapper {
    margin: 0 auto;
    margin-bottom: 24px;
    height: 150px;
    width: 300px;
    border: 2px solid var(--main-green-color-light);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
  }

  &-logout-wrapper {
    padding: 12px;
    display: flex;
    justify-content: flex-end;
  }

  &-board-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-hints-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: var(--regular-h1-font-size);
    color: var(--main-green-color-light);
    letter-spacing: 1px;
  }
}

.puzzles-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 500px;

  &-item {
    @extend .puzzles-container;
    align-items: center;
    width: 50%;
    height: 100px;
    border: 1px solid black;
    margin-top: 20px;
  }

  &-item:hover {
    background-color: rgb(209, 207, 207);
    cursor: pointer;
  }
}
