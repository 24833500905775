.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  mix-blend-mode: normal;
  background: rgba(1, 1, 1, 0.05);
  z-index: 100;
}

.modal-wrapper {
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .modal {
    right: 25px;
  }
}

.modal {
  width: 100%;
  position: absolute;
  background: var(--main-white-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  height: fit-content;
  box-sizing: border-box;

  &-header {
    width: 100%;
    color: var(--main-green-color-dark);
    line-height: 38px;
    display: flex;
    justify-content: center;

    h1 {
      font-size: var(--regular-h1-font-size);
      font-weight: 600;
      font-style: normal;
    }
  }

  &-paragraph {
    // position: absolute;
    width: 100%;
    // height: 3.5%;
    line-height: 19px;
    top: 30%;
    display: flex;
    justify-content: center;
    text-align: center;

    span {
      font-size: var(--regular-font-size);
      font-weight: 400;
      font-style: normal;
    }
  }

  &-game-selector {
    @extend .modal-paragraph;
    width: 100%;
    line-height: 19px;
    display: flex;
    justify-content: center;

    margin: 12px 0;

    span {
      font-weight: 600;
    }
  }

  &-buttons-container {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: var(--regular-font-size);
    margin-bottom: 12px;

    &-left-button {
      outline: none;
      background: var(--main-green-color-light);
      color: var(--main-white-color);
      border: 1px solid var(--main-green-color-light);
      margin-right: 12px;
      margin-left: 12px;
      font-weight: 600;
      line-height: 19px;
      width: 100%;
      padding: 8px 0;
      text-align: center;
      cursor: pointer;
    }

    &-right-button {
      @extend .modal-buttons-container-left-button;
      background: var(--main-white-color);
      color: var(--main-green-color-light);
    }
  }

  &-rules-container {
    @extend .modal-buttons-container;
    // top: 72.3%;
    // height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;

    &-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      margin-right: 12px;
      margin-left: 12px;

      &-mark {
        color: var(--main-green-color-light);
        font-weight: 600;
      }

      span {
        line-height: 19px;
      }
    }
  }
}
