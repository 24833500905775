.login-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 300px;
    border: 2px solid var(--main-green-color-light);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  input {
    width: 350px;
    height: 30px;
    border: 2px solid var(--main-green-color-light);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 5px;
    font-size: 20px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 30px;
    border: 2px solid var(--main-green-color-light);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 20px;
    font-size: 20px;
    color: var(--main-green-color-light);
  }

  button:hover {
    background-color: var(--main-green-color-light);
    color: var(--main-white-color);
    cursor: pointer;
  }

  h1 {
    color: var(--main-green-color-light);
  }
}
