.check-answer-wrap {
  display: none;
  position: fixed;
  width: 100%;
  bottom: 12px;
  flex-direction: column;
  align-items: center;
}

.check-answer {
  outline: none;
  background: white;
  color: var(--main-green-color-light);
  border: 1px solid var(--main-green-color-light);
  margin-right: 12px;
  margin-left: 12px;
  line-height: 19px;
  padding: 16px 32px;
  text-align: center;
  cursor: pointer;
  font-size: var(--regular-font-size);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}

.check-answer:disabled {
  border: 1px solid var(--text-grey-color);
  color: var(--text-grey-color);
}

@media only screen and (min-width: 1024px) {
  .check-answer-wrap {
    display: flex;
  }
}
