.board-wrapper {
  padding-bottom: 200px;
  margin: 0 auto;
}

.board {
  outline: none;
  display: table;

  &-lines {
    padding: 0 110px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    min-width: min-content;
  }

  &-line {
    gap: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px 0;
    background: #fff;
    position: relative;
  }

  &-line::before {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-color: var(--division-marks-color);
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: -1;
  }
}
