@import "../styles.scss";

.navbar-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  line-height: 29px;
  width: 100vw;
  z-index: 100;
  background: #fff;
  box-sizing: border-box;

  &-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
  }

  &-start-position {
    display: flex;
    flex-direction: column;
    align-items: center;

    .navbar-container-author {
      width: 100%;
      line-height: 1;
      font-size: calc(var(--regular-font-size) - 4px);
      text-align: right;
    }

    h1 {
      margin: 0;
    }
  }

  &-game-position {
    top: 0;
    left: 45.8%;
  }

  &-marks-wrapper {
    display: flex;
    align-items: center;
    left: 81.5%;
    height: 24px;

    &-question-mark {
      height: 100%;
      width: 14px;
      cursor: pointer;
    }

    &-reload-mark {
      height: 100%;
      width: 28px;
      cursor: pointer;
      padding-right: 14px;
    }

    &-tries-wrapper {
      min-width: 168px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 24px;

      &-item {
        height: 16px;
        width: 16px;
        border: 2px solid var(--main-green-color-light);
        border-radius: 50%;
        background: var(--main-green-color-light);
        margin-right: 6px;
      }

      &-empty-item {
        @extend .navbar-container-marks-wrapper-tries-wrapper-item;
        border: 2px solid var(--scrollbar-border);
        background-color: var(--scrollbar-background);
      }
    }
  }

  h1 {
    font-size: var(--navbar-h1-font-size);
    color: var(--main-letter-color);
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) {
  .navbar-container {
    box-shadow: none;
  }

  .navbar-container-start-position {
    flex-direction: row;
    gap: 12px;
  }
}
