@import "../styles.scss";

.window-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  mix-blend-mode: normal;
  background: rgba(1, 1, 1, 0.05);
  z-index: 100;
}

.window-wrapper {
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.window-container {
  position: absolute;
  height: 100%;
  padding: 24px 32px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background-color: var(--main-white-color);
  display: flex;
  flex-direction: column;
  z-index: 100;
  box-sizing: border-box;
  width: 100%;

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
  }

  &-close-icon {
    display: block;
    right: 20px;
    cursor: pointer;
    width: 17px;
    height: 17px;
  }

  &-main-header {
    line-height: 29px;
    color: var(--main-letter-color);

    h1 {
      font-size: var(--navbar-h1-font-size);
      font-weight: 600;
      font-style: normal;
      margin-block-start: 0em;
      margin-block-end: 0em;
    }
  }

  &-wrapper {
    width: 100%;
    height: 97%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;

    &-scroll {
      padding-right: 48px;
      &-header-without-padding {
        line-height: 29px;
        color: var(--main-letter-color);

        h1 {
          font-size: var(--navbar-h1-font-size);
          font-weight: 600;
          font-style: normal;
          margin-block-start: 0em;
          margin-block-end: 0em;
        }
      }

      &-header-with-padding {
        @extend .window-container-wrapper-scroll-header-without-padding;
        padding-top: 35px;
      }

      &-header-without-padding {
        @extend .window-container-wrapper-scroll-header-without-padding;
      }

      &-content {
        font-size: var(--regular-font-size);
        hyphens: auto;
      }

      &-points {
        padding-top: 10px;
        padding-left: 10px;
      }
    }
  }
}

#scrollbar-style::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;

  &-thumb {
    border-radius: 10px;
    background-color: var(--main-grey-color);
  }

  &-track {
    border-radius: 10px;
    background-color: var(--scrollbar-background);
    border: 1px solid var(--scrollbar-border);
  }
}

@media only screen and (min-width: 768px) {
  .window-container {
    right: 25px;
    height: 75%;
    border-radius: 10px;
    max-width: 400px;
  }
  .window-wrapper {
    display: block;
    top: 100px;
  }
}
