.letter {
  border: var(--first-cell-border-width) solid var(--first-cell-border);
  width: var(--regular-cell-width);
  height: var(--regular-cell-height);
  padding: 0;
  background: var(--first-cell-background);
  outline: none;
  font-size: var(--regular-h1-font-size);
  font-weight: 400;
  line-height: 38px;
  caret-color: transparent;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &-current {
    @extend .letter;
    border: var(--first-cell-border-width) solid var(--text-grey-color) !important;
  }

  &-last {
    @extend .letter;
    width: var(--last-cell-width);
    height: var(--last-cell-height);

    &-current {
      @extend .letter-last;
      border: var(--first-cell-border-width) solid var(--text-grey-color) !important;
    }
  }

  &-replaced-one-regular {
    @extend .letter;
    border: var(--replaced-letters-border) solid var(--replaced-letter-first);
  }

  &-replaced-one-last {
    @extend .letter-last;
    border: var(--replaced-letters-border) solid var(--replaced-letter-first);
  }

  &-replaced-two-regular {
    @extend .letter;
    border: var(--replaced-letters-border) solid var(--replaced-letter-second);
  }

  &-replaced-two-last {
    @extend .letter-last;
    border: var(--replaced-letters-border) solid var(--replaced-letter-second);
  }
}

.letter-blind {
  @extend .letter;
  z-index: -1;
}

.mark-blind {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.correct {
  @extend .letter;
}

.incorrect {
  @extend .letter;
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 2.11px),
      var(--croses-cell-color) 50%,
      rgba(0, 0, 0, 0) calc(50% + 2.11px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 10%,
      rgba(0, 0, 0, 0) calc(50% - 2.11px),
      var(--croses-cell-color) 50%,
      rgba(0, 0, 0, 0) calc(50% + 2.11px),
      rgba(0, 0, 0, 0) 100%
    );
}

.colored-letter {
  @extend .letter;

  &-1 {
    border-color: #a54505;
  }
  &-2 {
    border-color: #852d1d;
  }
  &-3 {
    border-color: #6a0707;
  }
  &-4 {
    border-color: #490110;
  }
  &-5 {
    border-color: #ab8100;
  }
  &-6 {
    border-color: #6f5502;
  }
  &-7 {
    border-color: #887d74;
  }
  &-8 {
    border-color: #342800;
  }
  &-9 {
    border-color: #001c44;
  }
  &-10 {
    border-color: #5f2228;
  }
  &-light {
    &-regular {
      background: #fff9d7 !important;
      border: var(--first-cell-border-width) solid var(--first-cell-border) !important;
    }
    &-bottom {
      background: #ffebd9 !important;
      border: var(--first-cell-border-width) solid var(--first-cell-border) !important;
    }
  }
}
