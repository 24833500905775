@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@400;600;700&display=swap");
:root {
  --regular-h1-font-size: 32px;
  --navbar-h1-font-size: 24px;
  --regular-font-size: 16px;
  --hints-font-size: 24px;

  --regular-cell-width: 45px;
  --regular-cell-height: 45px;
  --last-cell-width: 45px;
  --last-cell-height: 64px;
  --offset-between-cells: 8px;
  --offset-under-cells: 8px;

  --main-green-color-light: #22b573;
  --main-green-color-dark: #006837;
  --main-letter-color: #231f20;
  --main-white-color: #ffffff;
  --main-grey-color: #b3b3b3;
  --text-grey-color: #808080;

  --first-cell-border: #d0d2d3;
  --first-cell-background: #f6f8f9;
  --first-cell-border-width: 3px;

  --replaced-letters-border: 3px;
  --replaced-letter-first: #0071bc;
  --replaced-letter-second: #662d91;

  --croses-cell-color: rgba(153, 153, 153, 1);

  --division-marks-color: #bbbdbf;

  --scrollbar-border: #d0d2d3;
  --scrollbar-background: #f6fafd;
}

* {
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: var(--regular-font-size);
}

.game-wrapper {
  margin-top: 65px;
  padding-top: 12px;
  padding-bottom: 36px;
  box-sizing: border-box;
  max-width: 100vw;
  overflow: auto;
}
.mark {
  background: var(--division-marks-color);
  width: 2px;
  height: var(--regular-cell-height);
}
